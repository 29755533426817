<template>
  <div>
    <v-form ref="form" v-model="valid" v-if="features">
      <v-alert type="error" v-if="error" text>
        {{ error }}
      </v-alert>
      <v-alert type="success" v-if="success" text>
        {{ success }}
      </v-alert>
      <v-row no-gutters>
        <v-col v-for="(feature, index) in features" :key="index" cols="6">
          <v-switch
            v-if="!feature.disabled"
            v-model="form[feature.value]"
            :disabled="!editable"
            @change="onFormChange"
          >
            <template v-slot:label>
              <feature-icon v-model="feature.value"></feature-icon>
              {{ feature.text }}
            </template>
          </v-switch>
        </v-col>
      </v-row>
    </v-form>
    <div v-else class="text-center">
      <v-progress-circular
        indeterminate
        size="50"
        width="5"
        color="primary"
        class="mt-7"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import Feature from '../../services/feature';
import Organization from '../../services/organization';
import accMixin from '../../mixins/account';
const org = new Organization();
const feature = new Feature();
export default {
  mixins: [accMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FeatureIcon: () => import('@/components/Core/Features/FeatureIcon.vue')
  },
  data: () => ({
    features: null,
    form: {},
    valid: true,
    error: '',
    success: '',
    noUpdate: true
  }),
  created() {
    this.getFeatures();
    this.getData();
  },
  methods: {
    onFormChange() {
      const features = { ...this.form };
      if (!features) {
        return;
      }
      this.error = '';
      org
        .updateFeatures({ id: this.id, features })
        .then(() => {
          this.success = 'Features updated successfully';
          setTimeout(() => {
            this.success = '';
          }, 5000);
          this.getOrgPermissions();
        })
        .catch(error => {
          this.error = error;
          setTimeout(() => {
            this.error = '';
          }, 5000);
        });
    },
    async getFeatures() {
      this.features = feature.enabled('organization');
    },
    getData() {
      org.retrieve(this.id).then(res => {
        const data = res.data();
        if (data.features) {
          this.form = { ...data.features };
          this.$emit('input', this.form);
        }
      });
    }
  }
};
</script>

<style></style>
