import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.features)?_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.error)?_c(VAlert,{attrs:{"type":"error","text":""}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.success)?_c(VAlert,{attrs:{"type":"success","text":""}},[_vm._v(" "+_vm._s(_vm.success)+" ")]):_vm._e(),_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.features),function(feature,index){return _c(VCol,{key:index,attrs:{"cols":"6"}},[(!feature.disabled)?_c(VSwitch,{attrs:{"disabled":!_vm.editable},on:{"change":_vm.onFormChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('feature-icon',{model:{value:(feature.value),callback:function ($$v) {_vm.$set(feature, "value", $$v)},expression:"feature.value"}}),_vm._v(" "+_vm._s(feature.text)+" ")]},proxy:true}],null,true),model:{value:(_vm.form[feature.value]),callback:function ($$v) {_vm.$set(_vm.form, feature.value, $$v)},expression:"form[feature.value]"}}):_vm._e()],1)}),1)],1):_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{staticClass:"mt-7",attrs:{"indeterminate":"","size":"50","width":"5","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }